@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
.ant-form-item-label label {
  font-size: 17px;
  color: #fff;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error .ant-picker {
  background: #ffd2d2 !important;
}

.ant-picker {
  width: 100%;
}

.ant-btn.ant-btn-primary {
  width: 100%;
  border: #fff;
  border: 1px solid #fff;
}

.ant-upload {
  color: #fff;
}

.countdown-block {
  width: 59px;
  padding: 5px 0;
  height: 59px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: #466399;
  margin-right: 20px;
  text-align: center;
}

.countdown-block p {
  margin-bottom: 0;
  font-size: 10px;
}

.countdown-block p.time {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.whiteColor p {
  color: #fff;
  font-size: 17px;
  font-family: Poppins;
  letter-spacing: 0.2px;
}

.blackColor p {
  color: #000000;
}

.white-text * {
  color: white !important;
}

.ant-form-item-label label {
  font-size: 17px;
  color: #000000;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error .ant-picker {
  background: #ffd2d2 !important;
}

.ant-picker {
  width: 100%;
}

.ant-btn.ant-btn-primary {
  width: 100%;
  border: #fff;
  border: 1px solid #fff;
}

.ant-upload {
  color: #fff;
}

body {
  /* font-family: 'Poppins', Courier, monospace; */
  font-weight: 600;
  color: black;
}

button {
  background-color: #8d96ff;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.steps-content {
  min-height: 250px;
  margin-top: 20px;
  padding: 20px;
  /* //background-color: #fafafa; */
  background-color: #ffffff;
  /* //border: 1px dashed #e9e9e9; */
  border-radius: 5px;
}

.steps-action {
  margin-top: 20px;
  text-align: center;
}

.steps-action .btn {
  margin-right: 10px;
  width: 200px;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #8d96ff !important;
}

.ant-tabs-ink-bar {
  background-color: #8d96ff;
}

/* Change color of active menu item */
.ant-menu-item-selected {
  color: #8d96ff !important;
}

/* Change color of underline for active menu item */
.ant-menu-item-selected::after {
  border-bottom: 2px solid #8d96ff !important;
}

/* Change color of menu item on hover */
.ant-menu-item:hover {
  color: #8d96ff !important;
}

/* Change color of underline for menu item on hover */
.ant-menu-item:hover::after {
  border-bottom: 2px solid #8d96ff !important;
}

