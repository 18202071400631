.ant-form-item-label label {
  font-size: 17px;
  color: #000000;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error .ant-picker {
  background: #ffd2d2 !important;
}

.ant-picker {
  width: 100%;
}

.ant-btn.ant-btn-primary {
  width: 100%;
  border: #fff;
  border: 1px solid #fff;
}

.ant-upload {
  color: #fff;
}
