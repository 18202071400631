.whiteColor p {
  color: #fff;
  font-size: 17px;
  font-family: Poppins;
  letter-spacing: 0.2px;
}

.blackColor p {
  color: #000000;
}

.white-text * {
  color: white !important;
}
